import React from "react"
import PropTypes from "prop-types"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import BlogMainArea from "../components/BlogMainArea"
import Arrow from "../components/Arrow"

function Privacy({
  data: {
    markdownRemark: {
      html,
      frontmatter: { title, lang, description },
    },
  },
}) {
  return (
    <Layout lang={lang} title={title}>
      <BlogMainArea title={title} description={description} />
      <div className="b-homepage-content">
        <div className="b-layer-main">
          <Arrow />
          <div
            style={{ maxWidth: 1000, margin: "50px auto" }}
            dangerouslySetInnerHTML={{ __html: html }}
          />
        </div>
      </div>
    </Layout>
  )
}

Privacy.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      html: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
}

export default Privacy

export const query = graphql`
  query($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
        categories
        lang
        image {
          childImageSharp {
            fluid(maxWidth: 500) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
        }
      }
    }
  }
`
